<template>
    <div v-loading.fullscreen.lock="fullscreenLoading">
        <Navigation />

        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>
                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI文稿润色" />
                        <ai_upload @get_url="get_url" />
                        <!-- <ai_select upLoad_text_align="left" :minWidth="60" selectTitle="类型" :select_list='style_list'
                            @getSelectVal="getStyle" /> -->
                        <button class="create_but" @click="file_data">生成内容</button>
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_title from '@/components/ai_title.vue'
import ai_upload from '@/components/ai_upload.vue'
import ai_select from '@/components/ai_select.vue'
import ai_text_content from '@/components/ai_text_content.vue'
export default {
    name: '',
    created() { },
    mounted() {
        this.getUserInfo()
    },
    components: {
        wordNav,
        ai_title,
        ai_upload,
        ai_select,
        ai_text_content
    },
    data() {
        return {
            //type: '', // 行业
            //business: '',// 擅长领域
            //personal: '',// 个人荣誉
            style_list: [
                {
                    id: 0,
                    name: '缩写'
                },
                {
                    id: 1,
                    name: '延申'
                },
            ],
            form: {
                keyword: '', // 关键词
                style: '',
            },

            contentData: {},
            fullscreenLoading: false,
            aiNumShow: false,
            aiPoupShow: false,
        }
    },
    methods: {
        get_url(url) {
            this.form.keyword = url
        },
        getStyle(val) {
            this.form.style = val
        },
        getUserInfo() {
            let $user_info = JSON.parse(localStorage.getItem('user_info'))
            if ($user_info) {
                this.curlGet('/api/user/info').then((res) => {
                    if (res.data.code) {
                        this.$user_info = res.data.data
                        this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                    }
                })
            }
        },
        file_data() {
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }

        },
        postData() {
            if (this.keyword != '') {
                this.aiNumShow = false
                this.fullscreenLoading = true

                this.curlPost('file/article_polish', {
                    mobile: this.$user_info.mobile,
                    input_text: this.form.keyword,
                    // style: this.form.style,

                }).then(res => {
                    console.log(res);
                    if (res.data.code) {
                        this.fullscreenLoading = false
                        this.contentData = res.data.data

                        // 扣费
                        this.curlPost('/api/users/money_log/spend', {
                            ai_id: res.data.data.id,
                            amount: res.data.data.money,
                        }).then(res => {
                            if (res.data.code) {
                                this.getUserInfo()

                            }
                        })
                    }
                    this.fullscreenLoading = false
                })
            } else {
                this.aiNumShow = false
                return this.$message({
                    message: '禁止上传空文件!',
                    type: 'warning',
                    offset: 80
                });
            }
        },
        copy() {
            this.fullscreenLoading = true

            //参数是复制的内容
            //.then()中的函数是复制成功和复制失败的两个回调函数
            this.$copyText(this.generatedContent).then(
                (e) => {
                    this.fullscreenLoading = false
                    return this.$message({
                        message: '复制成功',
                        type: 'success',
                        offset: 80
                    });
                },
                (err) => {
                    this.fullscreenLoading = false

                    return this.$message({
                        message: '复制失败',
                        type: 'warning',
                        offset: 80
                    });
                }
            );
        },
        handleAvatarSuccess(res, file) {
            this.fullscreenLoading = true
            console.log(res);

            if (res.code == 1) {
                this.keyword = res.data.content
                this.fullscreenLoading = false
            } else {
                this.fullscreenLoading = false
            }

        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        uploadFn(response, file, fileList) {
            console.log(response, file, fileList);
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        err(err, file, fileList) {
            console.log(err, file, fileList);
        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai.scss'
</style>